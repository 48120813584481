<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
         <el-form-item label="分类">
          <el-select
            v-model="queryForm.reason"
            placeholder="请选择"
            clearable
            width="50px"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in reasonList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="举报人ID">
          <el-input
            placeholder="请输入举报人ID"
            v-model="queryForm.user_id"
          ></el-input>
        </el-form-item>
        <el-form-item label="帖子ID">
          <el-input
            placeholder="请输入帖子ID查询"
            v-model="queryForm.object_id"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="queryForm.is_read"
            placeholder="请选择"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in is_read_list"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="reportList"
        stripe
        class="table"
        ref="multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column label="编号" prop="id" align="center"></el-table-column>
        <el-table-column label="举报所属资源" prop="topic_info.msg" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="举报人昵称" prop="user_info.nickname" align="center"></el-table-column>
        <el-table-column label="举报时间" align="center">
          <template slot-scope="scope">
             {{scope.row.create_time}}
          </template>
        </el-table-column>
        <el-table-column label="举报分类" prop="reason" align="center"></el-table-column>
        <el-table-column label="举报截图" prop="pics" align="center" #default="scope">
          <div>
            <img :src="scope.row.pics" alt="" srcset="" style="width:100%" v-if="scope.row.pics">
            <div v-else>无</div>
          </div>
        </el-table-column>
        <el-table-column label="举报描述" prop="desc" align="center" show-overflow-tooltip #default="scope">
          <div>
            <div v-if="scope.row.desc">{{scope.row.desc}}</div>
            <div v-else>无</div>
          </div>
        </el-table-column>
        <el-table-column label="是否查阅" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.is_read == 1">
              已查阅
            </el-tag>
            <el-tag type="danger" v-else
              >未查阅
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="getReportDetail(scope.row)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 帖子详情 -->
      <el-dialog :title="addtitle" :visible.sync="reportDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
        <el-form :model="reportDetail"  ref="addForm">
          <el-form-item el-form-item label="举报人昵称：" label-width="100px">
            {{reportDetail.nickname}}
          </el-form-item>
          <el-form-item el-form-item label="举报人ID:" label-width="100px">
            {{reportDetail.user_id}}
          </el-form-item>
          <el-form-item el-form-item label="举报分类：" label-width="100px">
            {{reportDetail.reason}}
          </el-form-item>
          <el-form-item el-form-item label="举报时间：" label-width="100px">
            {{reportDetail.create_time}}
          </el-form-item>
          <el-form-item el-form-item label="举报截图：" label-width="100px">
            <div v-if="reportDetail.pics">
              <div style="display:flex;align-items:center;">
                <img :src="reportDetail.pic1" alt="" v-if="reportDetail.pic1" style="width: 42%;margin-right:10px;">
                <img :src="reportDetail.pic2" alt="" v-if="reportDetail.pic2" style="width: 42%;">
              </div>
              <div style="display:flex;align-items:center;margin-top:10px">
                <img :src="reportDetail.pic3" alt="" v-if="reportDetail.pic3" style="width: 42%;margin-right:10px;">
                <img :src="reportDetail.pic4" alt="" v-if="reportDetail.pic4" style="width: 42%;">
              </div>
            </div>
            <div v-else>无</div>
          </el-form-item>
          <el-form-item el-form-item label="举报描述：" label-width="100px">
            <div v-if="reportDetail.desc">{{reportDetail.desc}}</div>
            <div v-else>无</div>
          </el-form-item>
          <el-form-item el-form-item label="所属帖子ID:" label-width="100px">
            {{reportDetail.topic_id}}
          </el-form-item>
          <el-form-item el-form-item label="所属帖子：" label-width="100px">
            <template>
              <div>
                {{reportDetail.topic_msg}}
              </div>
            </template>
          </el-form-item>
          <el-form-item el-form-item label="处理情况：" label-width="100px">
            {{reportDetail.check}}
          </el-form-item>
        </el-form>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");
import {
  getReportList,
  setReportRead
} from "@/api/French/report_management.js";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        reason: "",
        is_read: null,
        user_id: "",
        object_id: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      reportList: [],
      reportDetail: {},
      reasonList: [{key:"与社区文化不符",value:"与社区文化不符"},{key:"谩骂造谣",value:"谩骂造谣"},
        {key:"广告传销",value:"广告传销"},{key:"诈骗",value:"诈骗"},
        {key:"色情",value:"色情"},{key:"暴力",value:"暴力"},
        {key:"反动",value:"反动"},{key:"抄袭",value:"抄袭"},
        {key:"其他",value:"其他"}],
      is_read_list: [{key:-1,value:"未查阅"},{key:1,value:"已查阅"}],
      total: 0,
      reportDialog: false,
      times: null,
      addtitle: ""
    };
  },

  created() {
    this.getList(this.queryForm);
  },
  methods: {
    //获取帖子列表
    getList(item) {
      getReportList(item).then((res) => {
        console.log(res)
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.reportList = res.data.data;
        console.log(this.reportList)
      });
    },

    // 点击编辑图标
    getReportDetail(item) {
      console.log(item,"111")
      this.addtitle = "举报详情";
      this.reportDialog = true;
      item.nickname = item.user_info.nickname;
      item.user_id = item.user_info.id;
      if(item.topic_info.check == 1){
        item.check = "未处理"
      }else if(item.topic_info.check == -1){
        item.check = "已禁用"
      }
      item.topic_id = item.topic_info.id;
      item.topic_msg = item.topic_info.msg;
      if(item.pics){
        item.pic1 = item.pics[0];
        item.pic2 = item.pics[1];
        item.pic3 = item.pics[2];
        item.pic4 = item.pics[3];
      }
      this.reportDetail = item;
      console.log(item)
      setReportRead({id:item.id}).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
      })
    },

    // 弹窗关闭事件
    closed() {
      this.getList(this.queryForm);
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        // this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        // this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
        this.queryForm.start_time = this.times[0].getFullYear()+ '-' +(this.times[0].getMonth() + 1) + '-' + this.times[0].getDate() 
	      + ' ' + this.checkTime(this.times[0].getHours()) + ':' + this.checkTime(this.times[0].getMinutes()) + ':' + 
        this.checkTime(this.times[0].getSeconds());
        this.queryForm.end_time = this.times[1].getFullYear()+ '-' +(this.times[1].getMonth() + 1) + '-' + this.times[1].getDate() 
	      + ' ' + this.checkTime(this.times[1].getHours()) + ':' + this.checkTime(this.times[1].getMinutes()) + ':' + 
        this.checkTime(this.times[1].getSeconds());
      } else if (this.times == null) {
        this.queryForm.start_time = "";
        this.queryForm.end_time = "";
      }
      console.log(this.queryForm)
      this.getList(this.queryForm);
    },
    //日期格式化
    checkTime(i) {//时间
      if (i < 10) {
        i = "0" + i
      }
      return i;
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getList(this.queryForm);
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getList(this.queryForm);
    },
  },
};
</script>

<style scoped>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
</style>
