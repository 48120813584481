import request from "@/utils/request";

//举报列表
export function getReportList(params) {
  return request({
      url: '/admin/french/getReportList',
      method: 'get',
      params
  })
}

//是否查阅
export function setReportRead(params) {
  return request({
      url: '/admin/french/setReportRead',
      method: 'get',
      params
  })
}


